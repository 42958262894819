import { IPinCredentialsResponse } from '@workerbase/api/http/user';
import { Languages } from '@workerbase/domain/common';
import { UserUsageTypes } from '@workerbase/domain/user';
import { RoleGET } from '@workerbase/types/api/roles';
import { SkillGET } from '@workerbase/types/api/skills';

export enum UsersCategories {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export interface LoggedUser {
  id: string;
  firstName: string;
  lastName: string;
  language: Languages;
  email: string;
  isOnPremise: boolean;
  isRootAdministrator: boolean;
  isDeveloper: boolean;
  disableDeviceLock: boolean;
  isWorkbenchEnabled: boolean;
  isMyWorkEnabled: boolean;
  isAiEnabled?: boolean;
  isGDriveEnabled?: boolean;
  isHideProfilePictureEnabled?: boolean;
  roleIds: string[];
}

// TODO: REfactor to  API Package
export interface User {
  id: string;
  firstName: string;
  lastName: string;
  active: boolean;
  language: Languages;
  department: string;
  email: string;
  phoneNumber: string;
  roles?: RoleGET[]; // will be undefined in getUser response
  rolesIds: string[];
  skills?: SkillGET[]; // will be undefined in getUser response
  skillsIds: string[];
  shiftPlanId: string;
  disableDeviceLock: boolean;
  isRootAdministrator: boolean;
  isDeviceOnlyUser: boolean;
  isDeveloper: boolean;
  locationName?: string;
  profilePicture?: string;
  usageType: UserUsageTypes;
  pinCredentials: Partial<IPinCredentialsResponse>;
}
