import { UserGET } from '@workerbase/types/api/users';

import { RoleGET } from '@workerbase/types/api/roles';
import { SkillGET } from '@workerbase/types/api/skills';
import { User } from '../types/User';

export function normalizeUser(user: UserGET): User;
export function normalizeUser(user: Partial<UserGET>): Partial<User>;
export function normalizeUser(user: UserGET | Partial<UserGET>): User | Partial<User> {
  const normalizedUser: User | Partial<User> = {
    id: user._id,
    firstName: user.firstName,
    lastName: user.lastName,
    active: user.active !== undefined ? user.active : true,
    language: user.language,
    department: user.department,
    email: user.email || '',
    phoneNumber: user.phoneNumber || '',
    profilePicture: user.profilePicture,
    roles: user.roles?.every((roleOrId) => typeof roleOrId !== 'string') ? (user.roles as RoleGET[]) : undefined,
    rolesIds: user.roles?.map((roleOrId) => (typeof roleOrId === 'string' ? roleOrId : roleOrId?._id)) || [],
    skills: user.skills?.every((skillOrId) => typeof skillOrId !== 'string') ? (user.skills as SkillGET[]) : undefined,
    skillsIds: user.skills?.map((skillOrId) => (typeof skillOrId === 'string' ? skillOrId : skillOrId?._id)) || [],
    shiftPlanId: user.shiftPlan || '',
    disableDeviceLock: user.disableDeviceLock || false,
    isRootAdministrator: user.superUser || false,
    isDeviceOnlyUser: user.watchOnly || false,
    isDeveloper: user.isDeveloper,
    locationName: user.location?.name,
    usageType: user.usageType,
    pinCredentials: user.pinCredentials,
  };

  return normalizedUser;
}
